.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}


/* Custom css */
.grecaptcha-badge{
  display: none !important;
}
/* Scrollbar Styling */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #afafaf; 
}

.auth-container {
  background-color: #fff;
}

.ant-checkbox-inner:after{
  top: 7px;
  left: 3px;
}

.pickup-address-title{
  display: flex;
  justify-content: space-between;
}

.search-address-modal .ant-list-items{
  max-height: 260px;
  overflow: auto;
  margin-top: 36px;
}

.addresslist-radio-group{
  width: 100%;
}

.addresslist-radio-group .ant-radio-inner{
  border-color: #52616b
}


/*Order Review*/
/* .place-order-container.container{
  width: 21cm;
  margin: 0 auto;
} */

.place-order-card-wrapper{
  border: 2px solid #333;
}

.place-order-barcode-img{
  transform: rotate(90deg) scale(.7);
  transform-origin: 10% 40%;
}

.place-order-sender-col{
  border-right: 2px dashed black;
  padding-right: 8px;
}

.place-order-receiver-col{
  padding-left: 8px;
}

.place-order-shipping-row{
  border-bottom: 2px dotted #8e8e8e;
  padding-bottom: 14px;
}

.place-order-preview-note{
  font-size: 14px;
}

.place-order-prepaid-img{
  transform: rotate(-20deg);
}

.order-status-ship-now{
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.payment-type-wrapper .ant-radio-button-wrapper{
  width: 160px;
}

.signup-tag-line{
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  margin-top: 40px;
  color: #866118;
}
.signup-terms-color{
  color: #fff;
}
.signup-form-tag .ant-form-item-label label{
  color: #728497;
  font-weight: normal;
}
.signup-form-tag {
  padding: 0 14px;
  background: #fff;
}
.signup-form-tag .register-col{
  background-color: #fff;
}

.courier-list-radio{
  border: 2px solid #cccccc;
  padding: 12px;
  border-radius: 50px;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-radio-wrapper.ship-order-courier-list-radio{
  border: 2px solid #cccccc;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courier-list-radio.ant-radio-wrapper-checked{
  border: 2px solid #3e79f7;
}
.courier-partner-price{
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
.ship-order-courier-partner-price{
  margin-left: 20px;
  font-weight: 500;
}
.courier-partner-wrapper img{
  width: 100px;
  height: 45px;
  object-fit: contain;
}
.ship-order-courier-partner-wrapper img{
  width: 50px;
}
.review-courier-partner-wrapper img{
  width: 200px;
  height: 90px;
  object-fit: contain;
 }
.demo-loadmore-list .ant-list-items{
  max-height: 300px;
  overflow: auto;
}

.shipping-calculator-drawer .ant-drawer-title{
  font-size: 20px;
}
.calculator-partner-wrapper{
  max-height: 45vh;
  overflow: auto;
  border-top: 1px solid #ebeff4;
}

.calculator-partner-price{
  font-size: large;
}
.calculator-partner-name{
  font-size: 11px;
}
.calculator-partner-img{
  width: 100px;
  height: 30px;
  object-fit: contain;
}
.calculator-cod-radio .ant-radio-button-wrapper{
  width: 160px;
}
.courier-provider-radio-group{
  display: block;
}
.shipOrderInfo label{
  min-width: 140px;
}
.ship-order-courrier-list-wrapper{
  overflow-y: auto;
  max-height: 200px;
}
/*End Order Review*/

/*auth css*/
.auth-title{
  color: #fff;
  line-height: normal;
  font-family: 'Lato';
  font-weight: 600;
}
.auth-title-one{
  font-size: 50px;
}
.auth-title-two{
  font-size: 40px;
}
.auth-title-three{
  font-size: 25px;
}
.auth-content-wrapper{
  color: rgb(255, 255, 255);
  border-left: 1px solid #fff;
  border-radius: 50px;
  padding: 50px;
}
.iframe-row{
  margin-right: unset !important;
}

/*Social Login*/
.google-button-class{
  box-shadow: unset !important;
  background-color: rgb(255, 255, 255) !important;
  display: inline-flex !important;
  align-items: center !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: Roboto, sans-serif !important;
  border: 1px solid rgba(0, 0, 0, 0.24) !important;
  border-radius: 12px !important;
  padding: 2px !important;
}
.google-button-class>div{
  margin-right: 10px;
  background: rgb(255, 255, 255);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.google-button-class>span{
  font-weight: 500;
  padding: 8px 8px 8px 0;
}
.facebook-button-class{
  font-family: Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: background-color .3s,border-color .3s;
  background-color: #4c69ba;
  border: calc(.06887vw + .67769px) solid #4c69ba;
  border-radius: 0.625rem;
  padding: 8.5px 1.25rem;
  letter-spacing: .4px;
}
.facebook-button-class i{
  margin-right: calc(.34435vw + 3.38843px);
  background: #fff;
  color: #4c69ba;
  padding: 4px;
  padding-bottom: unset;
  padding-right: 2px;
  font-size: 14px;
}
/*End Social Login*/

/*Header*/
.ant-layout .app-header.ant-layout-header{
  line-height: 20px;
}
.header-wallet-icon svg{
  border: 1px solid #000;
  border-radius: 6px;
}
.header-recharge-btn.ant-btn-primary{
  background: #fff;
  color: #000000;
  border-color: #000000;
  border-radius: 100px;
  border-width: 1px;
  font-weight: 500;
  padding: 0px 20px;
  font-size: 18px;
}
.header-recharge-btn.ant-btn-primary:hover,
.header-recharge-btn.ant-btn-primary:active,
.header-recharge-btn.ant-btn-primary:focus{
  box-shadow: 0 1px 2px 0px rgb(0 0 0 / 16%), 0 0px 0px 0 rgb(0 0 0 / 12%), 0 0px 0px 6px #00000030;
  background: #000000;
  color: #ffffff;
  border-color: #000;
}
/*End Header*/
/*End auth css*/

@media only screen and (min-width: 768px) {
  .auth-form-wrapper{
    padding: 34px;
    border-radius: 50px;
    border-right: 1px solid #434343;
    min-height: 80vh;
  }
}
@media only screen and (max-width: 767px) {
  .pickup-address-card .ant-card-head-title{
    white-space: unset;
  }
  .pickup-address-title-span{
    width: 190px;
  }
  .place-order-barcode-img, .header-wallet-icon{
    display: none;
  }
  .place-order-sender-col{
    border: unset;
  }
  
  /*integration*/
  .integration-image{
    width: 100px;
  }
  .integration-card .ant-card-body{
    padding: 0;
  }
  /*End integration*/
}
/* End Custom css */